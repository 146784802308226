import { createTRPCProxyClient, httpBatchLink, TRPCClientError, TRPCLink } from '@trpc/client';
import { Injectable } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { from, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { TrpcRouter } from '../types/trpc';
import { observable } from '@trpc/server/observable';
import { ErrorOverlayService } from '../error-overlay/error-overlay.service';
import { Router } from '@angular/router';
import { StateService } from './state.service';

// Note: only get and switch are allowed for non-admin under users
type Client = Pick<ReturnType<typeof createTRPCProxyClient<TrpcRouter>>, 'sso' | 'user' | 'organization' | 'course'>;

@Injectable({
  providedIn: 'root'
})
export class TrpcService {
  private readonly _client = createTRPCProxyClient<TrpcRouter>({
    links: [
      this.getErrorHandlerLink(),
      httpBatchLink({
        url: `${ environment.server }/trpc`,

        // We send __session cookie
        // https://trpc.io/docs/client/cors
        fetch(url, options) {
          return fetch(url, {
            ...options,
            headers:  {
              'X-Application': 'upskill'
            },
            credentials: 'include',
          });
        },
      }),
    ],
  });


  constructor(private auth: Auth,
              private router: Router,
              private state: StateService,
              private errorOverlay: ErrorOverlayService) {
  }

  get client(): Client {
    return this._client;
  }

  private getErrorHandlerLink(): TRPCLink<TrpcRouter> {
    const handleError = (error: TRPCClientError<any>, unrecoverableErrors?: number[]) => this.handleError(error);

    return () => {
      // here we just got initialized in the app - this happens once per app
      // useful for storing cache for instance
      return ({ next, op }) => {
        // this is when passing the result to the next link
        // each link needs to return an observable which propagates results
        return observable((observer) =>
          next(op).subscribe({
            next(value) {
              observer.next(value);
            },
            error(err) {
              handleError(err, (op.context as any)?.unrecoverableErrors as number[]);
              observer.error(err);
            },
            complete() {
              observer.complete();
            },
          }));
      };

    };
  }

  private handleError(error: TRPCClientError<any>) {
    if (!error.data || !error.shape) {
      this.errorOverlay.show({ title: 'Unknown error', details: error.message });
      return;
    }

    if (error.data.httpStatus === 500 && error.message === 'Server is in maintenance') {
      (window as any).MAINTENANCE = true;
      return;
    }

    if (error.data.httpStatus === 404 && error.message === 'Program not found') {
      this.state.select(state => state.user)
        .pipe(
          tap(user => {
            if (user!.homeUrl) {
              window.location.href = user!.homeUrl;
            } else {
              window.location.href = '/'
            }
          })
        )
        .subscribe();
      return;
    }

    if (error.data.httpStatus !== 401) {
      this.errorOverlay.show({ title: error.data.code, details: error.shape.message });
      return;
    }

    if (error.data.httpStatus === 401) {
      from(signOut(this.auth))
        .pipe(
          tap(() => this.router.navigate(['/sign-in']))
        )
        .subscribe();
    }
  }
}
