export const environment = {
  production: false,
  server: 'https://buildswell-dev-server.dev.learnswell.io',
  firebase: {
    apiKey: 'AIzaSyC3y_e2VRVZCNgtSiDihl_qVWN_-QdKMKg',
    authDomain: 'upskill-dev.dev.learnswell.io',
    storageBucket: 'buildswell-dev.appspot.com',
    projectId: 'buildswell-dev'
  },
  rollbarToken: '631133227974418ba5828ba4d8719757'
};
