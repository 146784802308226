import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorOverlayService {
  isShown = false;
  message: { title: string; details?: string; } | undefined;

  show(message: { title: string; details?: string; }): void {
    this.message = message;
    this.isShown = true;
  }
}
